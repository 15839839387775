body {
	margin: 0;
	padding: 0;
	font-family: sans-serif;
}

.current-page {
	font-size: 1.5rem;
	vertical-align: middle;
}

.country-card-container {
	height: 60px;
	cursor: pointer;
	position: relative;
	overflow: hidden;
}

.country-name {
	font-size: 0.9rem;
}

.country-region {
	font-size: 0.7rem;
}

.country-page,
.country-name,
.country-region {
	line-height: 1;
}

ul.pagination {
	margin-top: 0;
	margin-bottom: 0;
	box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}
ul.pagination li.page-item.active a.page-link {
	color: #445565 !important;
	background-color: #e3e7eb !important;
	border-color: #ced4da !important;
}
ul.pagination a.page-link {
    display: flex;
    justify-content: center;
    align-items: center;
	/* min-width: 3.5rem; */
	text-align: center;
	box-shadow: none !important;
	border-color: #ced4da !important;
	color: #6b88a4;
	font-weight: 900;
	font-size: 1rem;
}
ul.pagination a.page-link:hover {
	background-color: #f4f4f4;
}
